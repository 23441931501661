import React from 'react';
import { Link } from "react-router-dom";

import '../css/navbar.css'

// material ui
import CheckIcon from '@material-ui/icons/Check';

import UserAvatar from './UserAvatar';

import { Popover } from 'antd';


function NavBar(props) {
  const userAvatar = props.isAuthenticated ?
    ( <UserAvatar logout={props.handleLogout} />) :
    null;

  const loginButt = !props.isAuthenticated ?
    (
      <div className = "whiteButt navButt" onClick={props.showLogin} >
        <div className="center">
          Login
        </div>
      </div>
    ) :
    null;


  const popoverContent = <div className="popoverContent">
                            <h4>Publish</h4>
                            <p>Publish to save, and live update content on your website</p>
                            <a className = "helpLink" onClick = {() => props.showPublishModalStep("HOWTO")}>How to set up themelab.</a>
                          </div>

  return (
    <>
    <div className="appBar">
      <div className = "myWidth">
        {userAvatar}
        <Popover placement="bottomRight" content= {popoverContent}
                  trigger="hover">
          <div
            className="navButt publishButt"
            onClick={props.handlePublishClick}
          >
            <div className="center">
              <CheckIcon></CheckIcon><p>Publish</p>
            </div>
          </div>
        </Popover>
        <Link to={"/"}>
        <div className = "whiteButt navButt" style = {{float:"left"}}>
            <div className="center">
              Home
            </div>
          </div>
        </Link>
        <div className = "whiteButt navButt" style = {{float:"right"}} onClick = {props.showHowToModal} >
            <div className="center">
              How it Works
            </div>
          </div>
        {loginButt}
        <div className="appBarLogo">Themelab Editor</div>
      </div>
    </div>
    </>
  );
}

export default NavBar;
