import React from 'react';

import { Modal, Button, Tooltip, message } from 'antd';

import {
  Link
} from "react-router-dom";


const templatep1 = `<!doctype html>
<html lang="en">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
    <!--themelab CSS-->
    `

const templatep2 = `
    <title>My New Theme</title>
  </head>
  <body>
    <h1>Hello, my new theme!</h1>

    <!-- Welcome to your new theme! Want to change the elements here to include other colors from your theme (like dark, etc)? -->
    <!-- Do this by changing the color in the class (i.e. "primary") to the color you want (like "dark)! -->

    <!--Primary button-->
    <button type="button" class="btn btn-primary">Primary</button>
    <!--Secondary button-->
    <button type="button" class="btn btn-secondary">Primary</button>

    <!--Input-->
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
      <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
    </div>

    <!--Navbar-->
    <nav class="navbar navbar-expand-lg bg-light">
      <a class="navbar-brand" href="#">Navbar</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li>
        </ul>
      </div>
    </nav>

    <!-- Optional JavaScript -->
    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>
  </body>
</html>
`


class PublishModal extends React.Component {

  constructor(props){
    super(props);

    this.state = { visible: false };

    this.handleCancel = this.handleCancel.bind(this);

  }

  handleCancel(){
    this.props.handleClose();
  };

  copyCode(line){
    navigator.clipboard.writeText(line);
    message.success('Code copied to clipboard. Paste it in your HTML!');
  }

  downloadTemplate(line){
    let myTemplate = templatep1 + line + templatep2
    navigator.clipboard.writeText(myTemplate);
    message.success('Website template code copied to clipboard. Save it as HTML and run it!');
  }

  componentDidUpdate(prevprops, prevstate){
    if (this.props.publishStep && (this.props.publishStep !== prevprops.publishStep)) {
      this.setState({
        publishStep: this.props.publishStep
      })
    }
  }

  render() {

    let header2 = null;
    let sub2 = null;
    let body = null;
    let myCalltoAction = null;

    switch(this.state.publishStep){
      case 'PUBLISHED':
        header2 = "Congrats on Publishing!"
        sub2 = "Complete 1 more simple step to setting up your theme"
        myCalltoAction = (<Link to={"/editor"} onClick = {this.handleCancel}><button className ="myButton tallBoi" >
              Edit your new theme!
            </button></Link>)
        break;
      case 'HOWTO':
        header2 = "How it Works"
        sub2 = "2 simple steps!"
      break;
    }

    const visible = (this.props.publishStep) ? true : false

    const codeLine = '<link rel="stylesheet" href="' + this.props.userCssLink + '">'


    return (
      <div>
        <Modal
          visible={visible}
          footer={null}
          onCancel={this.handleCancel}
        className = "authModal"
        >
          <div className = "headerContent">
            {(header2) ? <h4>{header2}</h4> : null}
            {(sub2) ? <p>{sub2}</p> : null}
          </div>
          <div className = "howToContent">
            <div className = "howToSection">
              <h5>1. Paste this code into your HTML file</h5>
              <p style={{marginBottom: 5}}>Copy and paste this into your HTML directly below your bootstrap stylesheet.</p>
              <p>Each user has a unique link, so you only have to do this once!</p>


              {this.props.isAuthenticated ?
              <Tooltip placement="top" title="Click to copy code!">
                <div className = "codeHolder" onClick={() => this.copyCode(codeLine)}>{codeLine}</div>
              </Tooltip> :
              <Tooltip placement="top" title="Click to log in!">
                <div className = "codeHolder" onClick={() => this.props.showAuthFlowStep("LOGIN")}>Log in to get your unique code!</div>
                </Tooltip>
              }

              {this.props.isAuthenticated ?
              <p>Just getting started? <a onClick = {() => this.downloadTemplate(codeLine)} className = "helpLink">Download our website starter template!</a></p> :
              <p>Just getting started? <a onClick = {() => this.props.showAuthFlowStep("LOGIN")} className = "helpLink">Download our website starter template!</a></p>
               }

            </div>
            <div className = "howToSection">
              <h5>2. Choose or create a theme to publish!</h5>
              <p style={{marginBottom: 5}}>
                You can pick a premade theme from the <Link to={"/"} onClick = {this.handleCancel} className = "helpLink">Homepage</Link>, or create your own in the <Link to={"/editor"} onClick={this.handleCancel} className = "helpLink">Theme Editor</Link>.
              </p>
              <p>Click "Publish" and all changes will live update on your website.</p>
              <p>Don't worry! Your link will never change.</p>
            </div>
            {myCalltoAction}
          </div>
        </Modal>
      </div>
    );
  }
}

export default PublishModal;
