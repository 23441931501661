import React, { Component } from 'react';

// Import Components
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

// Takes in CSS styling as props and applies it to the given element
class AddElement extends Component {
  render() {
    return (
      <Grid item xs={12} md={12} lg={12} className="blockElement">
        <div onClick={this.props.showElSelector} className="elementCenter addElement" style = {{padding: "50px 0px"}}>
          <p style = {{textAlign: "center", marginBottom: "0px"}}>
            Add an Element
            <AddIcon />
          </p>
        </div>
      </Grid>
    );
  }
}

export default AddElement;
