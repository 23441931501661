// Stores a list of attribute along with input type
// Called in InputType.js
export default {

  "border-radius": {
    title: "Border Radius",
    cssVals: {
      0: {
        type: "slider",
        min: 0,
        max: 100,
        init: 30,
        val: 30
      },
    }
  },

  "box-shadow": {
    title: "Box Shadow",
    cssVals: {
      0: {
        type: "text",
        values: {
          0: "normal",
          1: "inset",
        },
        init: 0,
        val: 0
      },
      1: {
        type: "slider",
        min: -15,
        max: 15,
        init: 0,
        val: 0
      },
      2: {
        type: "slider",
        min: -15,
        max: 15,
        init: 0,
        val: 0
      },
      3: {
        type: "slider",
        min: 0,
        max: 25,
        init: 0,
        val: 0
      },
      4: {
        type: "slider",
        min: -15,
        max: 15,
        init: 0,
        val: 0
      },
      5: {
        type: "colorPicker",
        init: {r: 0, g: 0, b: 0, a: 0},
        val: {r: 0, g: 0, b: 0, a: 1}
      }
    }
  },

  "border": {
    title: "Border",
    cssVals: {
      0: {
        type: "slider",
        min: 0,
        max: 15,
        init: 0,
        val: 0
      },
      1: {
        type: "text",
        values: {
          0: "solid",
          1: "dotted",
          2: "dashed",
          3: "double",
        },
        init: 0,
        val: 0
      },
      2: {
        type: "colorPicker",
        init: {r: 0, g: 0, b: 0, a: 0},
        val: {r: 0, g: 0, b: 0, a: 1}
      }
    }
  },

  "padding": {
    title: "Padding",
    cssVals: {
      0: {
        type: "slider",
        min: 0,
        max: 40,
        init: 6,
        val: 6
      },
      1: {
        type: "slider",
        min: 0,
        max: 40,
        init: 12,
        val: 12
      },
    }
  },

  "font-size": {
    title: "Font-Size",
    cssVals: {
      0: {
        type: "slider",
        min: 0,
        max: 40,
        init: 16,
        val: 16
      }
    }
  },

  "height": {
    title: "Height",
    cssVals: {
      0: {
        type: "slider",
        min: 0,
        max: 100,
        init: 56,
        val: 56
      }
    }
  }

};
