import React from 'react';

// antd
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Avatar from 'antd/es/avatar';


import {
  Link
} from "react-router-dom";

function UserAvatar(props) {
  const menu = (
    <Menu>
      <Menu.Item onClick={props.logout}>
           <Link to={"/"}>Logout</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['hover']}>
      <Avatar className = "myAvatar"
        size="large"
        icon='user'
      />
    </Dropdown>
  );
}

export default UserAvatar;

        // style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
