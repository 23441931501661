import React from 'react';

import Form from 'antd/es/form';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';

function ForgotPasswordForm(props) {
  const { getFieldDecorator } = props.form;

  function handleSubmit(event) {
    event.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.handleSubmit(values);
      }
    });
  }

  return (
    <Form
      onSubmit={handleSubmit}
    >
      <Form.Item>
				{getFieldDecorator('username', {
					rules: [{ required: true, message: 'Please input your username!' }],
				})(
					<Input
            size = "large"
						prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
						placeholder="Username"
					/>,
				)}
      </Form.Item>
      <button className="myButton tallBoi" type="submit">
        Send Code
      </button>
    </Form>
  );
}

const WrappedForgotPasswordForm = Form.create({ name: 'forgot_password_form' })(ForgotPasswordForm);
export default WrappedForgotPasswordForm;
