import React, { Component } from 'react';

import { Col, Tooltip } from 'antd';

import pic1 from './sampleTheme.png'

import {themeDic} from './themes/themes.js'

import Popconfirm from 'antd/es/popconfirm';


class ThemePic extends Component {

  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    if (this.props.isAuthenticated === true){
      this.props.publish(themeDic[this.props.title]);
    } else {
      this.props.showPubLogin();
    }
  }

  render() {

    let classy = this.props.title + " backing";

    const disabled = (!this.props.isAuthenticated);

    const clickFunction = (this.props.isAuthenticated) ? null : this.handleClick;

    return (
      <Col xs={8} sm={8} md={8} lg={8} xl={8} className = "holdImage">
          <Popconfirm disabled = {disabled} placement="bottom" title="Publishing will overrite your existing theme, would you like to continue?" onConfirm={this.handleClick} okText="Yes" cancelText="No">
            <Tooltip placement="top" title="Click to Publish!">
            <div className = {classy} style = {{background: this.props.background}} onClick = {clickFunction}>
              <div className = "mycolorContainer">
                <div className = "colorEl" style = {{background: this.props.primary}} />
                <div className = "colorEl" style = {{background: this.props.secondary}} />
                <div className = "colorEl" style = {{background: this.props.dark}} />
                <div className = "colorEl" style = {{background: this.props.light}} />
              </div>
              <p className = "themeTitle">{this.props.title}</p>
              <div className = "positionButton demo">
                 <div className = "myButton">Button</div>
              </div>
            </div>
            </Tooltip>
        </Popconfirm>
      </Col>
    );
  }
}

export default ThemePic;