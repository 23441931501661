// This file is basically used to make sure that you can't add a component more than once
// It could probably be implemented in a better way, but long story short active is incremented to 0
// and thus it doesn't pop up in the "Add Element" Modal

// allowColors indicates whether or not color variations can be added
// colors stores the variations so you cant add the same one twice..
export default {

    "button": {
        name: "Button",
        allowColors: true,
        allowBase: false,
        colors: {}
    },

    "card": {
    	name: "Card",
    	allowColors: true,
    	allowBase: true,
    	colors: {}
    },

    "input": {
        name: "Input",
        allowColors: false,
        allowBase: true,
        colors: {}
    },

    "navbar": {
        name: "Navbar",
        allowColors: true,
        allowBase: false,
        colors: {}
    }
};

    // "list": {
    //     name: "Input",
    //     allowColors: false,
    //     allowBase: true,
    //     colors: {}
    // },


    // "badge": {
    //     name: "Badge",
    //     allowColors: true,
    //     allowBase: false,
    //     colors: {}
    // },

    // "alert": {
    //     name: "Alert",
    //     allowColors: true,
    //     allowBase: false,
    //     colors: {}
    // },

    // "modal": {
    //     name: "Input",
    //     allowColors: false,
    //     allowBase: true,
    //     colors: {}
    // },




