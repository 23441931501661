import React, { Component } from 'react';

// Import Material Elements
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from 'antd';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

class CloseButton extends Component {
  render() {
    return (
      <div className="modalCloser">
        <Button icon="close" shape="circle" onClick={this.props.close} className = "navButton" />
      </div>
    );
  }
}

export default CloseButton;
