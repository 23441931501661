import React, { Component } from 'react';

// Import Components
import Grid from '@material-ui/core/Grid';
import FontPicker from "font-picker-react";

// Material UI Stuff
import Typography from '@material-ui/core/Typography';

import ColorSelect from '../contentComponents/ColorSelect'


class TextModal extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      activeFontFamily: "Roboto"
    };
  }
   
  render() {

    return (
        <Grid container>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography key={999} id="input-slider" gutterBottom color="secondary">
                    Font
                  </Typography>
                  <FontPicker
                    apiKey="AIzaSyAferoR1a0vspBDQ2xmDnxajbA56My_t5I"
                    activeFontFamily={this.props.activeFontFamily}
                    onChange={nextFont => {
                                          this.props.setNewFont(nextFont.family)
                                          this.props.updateText("font", nextFont.family)
                                          }
                    }
                  />
                </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography key={998} id="input-slider" gutterBottom color="secondary">
              Color
            </Typography>
            <ColorSelect colorfun={(x,y) => this.props.updateText("color", y)} type={"type"} color={{r: 0, g: 0, b: 0, a: 1}} />
          </Grid>
        </Grid>
    );
  }
}

export default TextModal;