import React, { useState, useEffect } from 'react';
import { Auth, API } from "aws-amplify";

// Adding CSS
import './App.css';

// antd
import message from 'antd/es/message';
import notification from 'antd/es/notification';
import Button from 'antd/es/button';

// Import Components
import Controller from './Controller.js';
import Homepage from './homepageComponents/Homepage.js';
import AuthFlowModal from './publishComponents/AuthFlowModal';
import PublishModal from './publishComponents/PublishModal';

// Import Fonts
import 'typeface-roboto';

// React router quickie
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [authModalStep, setAuthModalStep] = useState(null);
  const [publishModalStep, setPublishModalStep] = useState(null);
  const [userCssLink, setUserCssLink] = useState(null);
  const [username, setUsername] = useState(null);

  // We will remove this eventually
  const [showPubHelp, setShowPubHelp] = useState(true);

  // run onLoad() on the first render
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      userHasAuthenticated(true);
      setUsername(user.username);
      setUserCssLink("https://" + process.env.REACT_APP_STAGE + "-themelab-themes.s3.amazonaws.com/public/" + user.username + "/theme.css");
    }
    catch(err) {
      console.log(err);
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    setUserCssLink(null);
    setUsername(null);
    message.success("Logged out");
  }

  // Publishes data to S3 and saves to dynamoDB
  async function publish(data) {
    console.log("YOUR OBJECT:")
    console.log(JSON.stringify(data));
    API.post('cardlabApi', '/themes', {
      body: {
        content: data,
        username: username
      }
    }).then(resData => {
      setUserCssLink(resData);

      if (showPubHelp){
        showPublishModalStep("PUBLISHED");
        setShowPubHelp(false);
      }

      notification['success']({
        message: "Success!",
        description: <div className = "helpLinkContainer"><p>Your styling has been published! </p><p>First time publishing? <a className = "helpLink" onClick = {() => showPublishModalStep("PUBLISHED")}>How to set up themelab.</a></p></div>,
        duration: 5
      });
    }).catch(err => {
      console.log(err);
      console.log(err.message);
      console.log(err.response);
      notification['error']({
        message: "Error.",
        description: "Something went wrong while publishing.",
        duration: 5
      });
    });
  }

  // Show the proper step in the authflow based on stepCode
  // Possible values for stepCode:
  // - LOGIN
  // - PUBLOGIN
  // - SIGN_UP
  // - CONFIRM_CODE
  // - FORGOT_PASSWORD
  // - REPLACE_PASSWORD
  function showAuthFlowStep(stepCode) {
    setAuthModalStep(stepCode);
  }

  function handleAuthModalClose(code, data) {
    setAuthModalStep(null);

    switch (code) {
      case 'SUCCESSFUL_LOGIN':
        notification['success']({
          message: "Success!",
          description: "You are logged in. Click a theme on the homepage or click publish in the theme editor to deploy a new theme.",
          duration: 5
        });
        userHasAuthenticated(true);
        setUsername(data.username);
        setUserCssLink("https://" + process.env.REACT_APP_STAGE + "-themelab-themes.s3.amazonaws.com/public/" + data.username + "/theme.css");
        break;
      case 'NOT_CONFIRMED':
        const key = `open${Date.now()}`;
        const btn = (
          <Button
            type="link"
            onClick={() => {
              notification.close(key);
              showAuthFlowStep('CONFIRM_CODE');
            }}
          >
            Click here and enter your unique code
          </Button>
        );
        notification['warning']({
          message: "Confirm Account",
          description: "It looks like you haven't confirmed your account yet.",
          btn,
          key,
          duration: null
        });
        break;
      default:
        if (code !== null) {
          console.log("Auth exit code not handled: ", code);
        }
    }
  }

  // Show the proper step in the publish modal based on stepCode
  // Possible values for stepCode:
  // - HOWTO
  // - PUBLISHED
  function showPublishModalStep(stepCode) {
    setPublishModalStep(stepCode);
  }

  function handlePublishModalClose() {
    setPublishModalStep(null);
  }

  return (
		!isAuthenticating && (
			<div className="App">

        <Router>
        <Switch>
        <Route exact path = "/">
          <Homepage
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
            showAuthFlowStep={showAuthFlowStep}
            publish={publish}
            showPubModal = {() => showPublishModalStep("HOWTO")}
          />
        </Route>
        <Route path="*">
          <Controller
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
            showAuthFlowStep={showAuthFlowStep}
            publish={publish}
            showPublishModalStep={showPublishModalStep}
          />
        </Route>
        </Switch>
        <PublishModal
          showAuthFlowStep={showAuthFlowStep}
          isAuthenticated={isAuthenticated}
          publishStep={publishModalStep}
          handleClose={handlePublishModalClose}
          userCssLink={userCssLink}
        />
        </Router>
        <AuthFlowModal
          authStep={authModalStep}
          handleClose={handleAuthModalClose}
          showAuthFlowStep={showAuthFlowStep}
        />
			</div>
		)
  );
}

export default App;
