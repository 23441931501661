import React, { Component } from 'react';

import { Menu, Dropdown, Icon } from 'antd';

class TextInput extends Component {

  constructor(props){
    super(props);
    this.state = {activeEl: this.props.values[this.props.init]}
  }

  handleClick(key){
    this.props.updateMe(key);
    this.setState({
      activeEl: this.props.values[key]
    });
  }

  render(){



    const menu = Object.keys(this.props.values).map(key => 
      
        <Menu.Item key ={key}>
          <a target="_blank" rel="noopener noreferrer" onClick = {() => this.handleClick(key)}>
            {this.props.values[key]}
          </a>
        </Menu.Item>
    );

    return(
          <Dropdown overlay={<Menu>{menu}</Menu>}>
            <a className="ant-dropdown-link myDropdown" >
              {this.state.activeEl} <Icon type="down" />
            </a>
          </Dropdown>
      )
    }
  }

export default TextInput