import React from 'react';

import Form from 'antd/es/form';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';

function LoginForm(props) {
  const { getFieldDecorator } = props.form;

  function handleSubmit(event) {
    event.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.handleSubmit(values);
      }
    });
  }

  return (
    <Form
      onSubmit={handleSubmit}
    >
      <Form.Item>
				{getFieldDecorator('username', {
					rules: [{ required: true, message: 'Please input your username!' }],
				})(
					<Input
						size = "large" 
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
						placeholder="Username"
					/>,
				)}
      </Form.Item>
			<Form.Item className = "smallBottomMargin">
				{getFieldDecorator('password', {
					rules: [{ required: true, message: 'Please input your Password!' }],
				})(
					<Input
            size = "large" 
						prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
						type="password"
						placeholder="Password"
					/>,
				)}
			</Form.Item>
      <div className="forgotPass" onClick={props.showForgotPassword}>
        <div className = "myLink">Forgot Password?</div>
      </div>
      <button className="myButton tallBoi" type = "submit" style = {{marginBottom: 20}} >
        Log in
      </button>
      <div className = "customFooter">  
        <p>First time on the site? <em onClick = {props.showSignUp}>Create an account!</em></p>
      </div>
    </Form>
  );
}

const WrappedLoginForm = Form.create({ name: 'login_form' })(LoginForm);
export default WrappedLoginForm;

      
      // <div className="myButton greenBoi tallBoi" onClick = {props.showSignUp}>
      //   Create an Account
      // </div>
