// Stores all of the tools to turn a type (like "card" or "navbar") into a css component
// This means className, etc.
// Also stores the type of element that the class is (i.e. "div" or "button")
// USED IN THE CONROLLER.JS File
export default {

  button: {
    class: "btn",
    classColor: "btn-",
    divType: "button"
  },

  card: {
  	class: "card",
    classColor: "bg-",
  	addClass: "card-1",
  	divType: "div"
  },  

  input: {
    class: "form-group",
    divType: "div"
  },

  navbar: {
    class: "navbar navbar-expand-lg",
    classColor: "bg-",
    addClass: "navbar-1",
    divType: "nav"
  }
};

  //   list: {
  //   class: "list-group",
  //   addClass: "list-1",
  //   divType: "ul"
  // },

  // badge: {
  //   class: "badge",
  //   classColor: "badge-",
  //   addClass: "",
  //   divType: "div"
  // },

  // alert: {
  //   class: "alert",
  //   classColor: "alert-",
  //   addClass: "",
  //   divType: "div"
  // },

  // modal: {
  //   class: "modal",
  //   classColor: "",
  //   addClass: "",
  //   divType: "div"
  // },