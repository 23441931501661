import React, { Component } from 'react';

// Import Components
import Grid from '@material-ui/core/Grid';

import CompElement from './CompElement';

import { Button, Icon, message, Tooltip } from 'antd';

import componentHTML from './allComponents/loadComponentsHTML.js'

// Takes in CSS styling as props and applies it to the given element
class CompElementContainer extends Component {

  toUpper(str){
    var splitStr = str.toLowerCase().split(' ');
     for (var i = 0; i < splitStr.length; i++) {
         // You do not need to check if i is larger than splitStr length, as your for does that for you
         // Assign it back to the array
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
     }
   // Directly return the joined string
   return splitStr.join(' '); 
  }

  copyCode(type){
    let copyText = componentHTML(type);
    navigator.clipboard.writeText(copyText);
    message.success('Code copied to clipboard. Paste it in your HTML!');
  }

  render() {

    const comps = []

    let type = this.props.css.type

    Object.keys(this.props.colors).forEach(color => {
      comps.push(
        <div key = {color} className = "positionSubtitle">
          <CompElement color = {color} {...this.props} />
          <p className = "mySubtitle">{color} {type}</p>
        </div>
        )
    })

    const title = this.toUpper(this.props.css.type)

    return (
      <Grid item xs={12} md={12} lg={12} className = "blockElement" style = {{position: "relative"}} >

        <div className="elementCenter" >
          <div className = "clickyBoi" onClick = {this.props.showMo} >
          <h5 className = "title" >{title}</h5>
          <p className = "helperText" >Click to Edit Styling</p>
            {comps}
          </div>
        <Button className = "addElementButton" icon = "plus" onClick={this.props.showElSelector} >add {this.props.css.type}</Button>
        <Tooltip placement="top" title="Copy Element Code">
          <Button className = "htmlButton" icon = "code" onClick={() => this.copyCode(this.props.css.type)} ></Button>
        </Tooltip>
        </div>

      </Grid>
    );
  }
}

export default CompElementContainer;


        // {this.state.displayColorOption ? <ComponentBadgeMenu colorOptions = {this.props.color} activeElement = {this.state.activeElement} updateActiveColor = {this.updateActiveColor} /> : <div/>}


