import React, { Component } from 'react';

import message from 'antd/es/message';
import Modal from 'antd/es/modal';

import { Auth } from 'aws-amplify';

import WrappedLoginForm from './LoginForm';
import WrappedSignUpForm from './SignUpForm';
import WrappedConfirmCodeForm from './ConfirmCodeForm';
import WrappedForgotPasswordForm from './ForgotPasswordForm';
import WrappedReplacePasswordForm from './ReplacePasswordForm';

import { Icon } from 'antd';


class AuthFlowModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null
    }

    this.resetState = this.resetState.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.resendCode = this.resendCode.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleSignUpSubmit = this.handleSignUpSubmit.bind(this);
    this.handleConfirmCodeSubmit = this.handleConfirmCodeSubmit.bind(this);
    this.handleForgotPasswordSubmit = this.handleForgotPasswordSubmit.bind(this);
    this.handleReplacePasswordSubmit = this.handleReplacePasswordSubmit.bind(this);
  }

  // This solves the error when the modal changes on shrink
  componentDidUpdate(prevprops, prevstate){
    if (this.props.authStep && (this.props.authStep !== prevprops.authStep)) {
      this.setState({
        authStep: this.props.authStep
      })
    }
  }


  resetState() {
    this.setState({
      username: null,
      password: null
    });
  }

  handleClose(code, data) {
    if (code === 'SUCCESSFUL_LOGIN') {
      this.resetState();
    }

    if (code === 'CONFIRM_CODE') {
      this.props.handleClose('NOT_CONFIRMED', data);
    } else {
      this.props.handleClose(code, data);
    }
  }

  resendCode() {
    Auth.resendSignUp(this.state.username)
      .then(() => {
        message.success('code resent successfully');
      })
      .catch(err => {
        message.error(err);
      });
  }

	handleSubmit(values) {
    console.log(values);
    switch (this.props.authStep) {
      case 'LOGIN':
      case 'PUBLOGIN':
        this.setState({
          username: values.username,
          password: values.password
        });
        this.handleLoginSubmit(values);
        break;
      case 'SIGN_UP':
        this.setState({
          username: values.username,
          password: values.password
        });
        this.handleSignUpSubmit(values);
        break;
      case 'CONFIRM_CODE':
        this.handleConfirmCodeSubmit(values);
        break;
      case 'FORGOT_PASSWORD':
        this.setState({ username: values.username });
        this.handleForgotPasswordSubmit(values);
        break;
      case 'REPLACE_PASSWORD':
        this.handleReplacePasswordSubmit(values);
        break;
      default:
        message.error('Error: Could not submit form. Auth step not recognized.');
    }
  }

  async handleLoginSubmit(values) {
    try {
      await Auth.signIn(values.username, values.password);
      this.handleClose('SUCCESSFUL_LOGIN', {
        username: values.username
      });
    } catch(err) {
      console.log(err);
      if (err.code === "UserNotConfirmedException") {
        this.resendCode();
        this.props.showAuthFlowStep('CONFIRM_CODE');
      } else {
        message.error(err.message);
      }
    }
  }

  async handleSignUpSubmit(values) {
    try {
      await Auth.signUp({
        username: values.username,
        password: values.password,
        attributes: {
          email: values.email
        }
      });
      this.props.showAuthFlowStep('CONFIRM_CODE');
    } catch(err) {
      console.log(err);
      message.error(err.message);
    }
  }

  async handleConfirmCodeSubmit(values) {
    try {
      await Auth.confirmSignUp(this.state.username, values.verificationCode);
      await Auth.signIn(this.state.username, this.state.password);
      this.handleClose('SUCCESSFUL_LOGIN', {
        username: this.state.username
      });
    } catch(err) {
      console.log(err);
      if (err.code === "NotAuthorizedException") {
        this.props.showAuthFlowStep('LOGIN');
      } else {
        message.error(err.message);
      }
    }
  }

  async handleForgotPasswordSubmit(values) {
    try {
      await Auth.forgotPassword(values.username);
      message.success('Code sent successfully');
      this.props.showAuthFlowStep('REPLACE_PASSWORD');
    } catch (err) {
      console.log(err);
      message.error(err.message);
    }
  }

  async handleReplacePasswordSubmit(values) {
    try {
      await Auth.forgotPasswordSubmit(this.state.username, values.verificationCode, values.password);
      message.success('Successfully replaced password');
      this.props.showAuthFlowStep('LOGIN');
    } catch (err) {
      console.log(err);
      message.error(err.message);
    }
  }

  render() {
    const showModal = this.props.authStep ? true : false;

    let header = null;
    let header2 = null;
    let sub2 = null;
    let additionalContent = null;
    let form = null;
    let footer = null;
    switch (this.state.authStep) {

// THE NON AUTH COMPONENTS GOING HERE (DESIGNED FOR PUBLISH WORKFLOW)

      case 'PUBLOGIN':
        header2 = "Publish your Theme!";
        sub2 = "Log in or create an account to deploy your theme!";
        form = (
          <WrappedLoginForm
            handleSubmit={this.handleSubmit}
            showForgotPassword={() => this.props.showAuthFlowStep('FORGOT_PASSWORD')}
            showSignUp={() => this.props.showAuthFlowStep('SIGN_UP')}
          />
        );
        break;

// AUTH COMPONENTS ARE HERE

      case 'LOGIN':
        header2 = "Login";
        sub2 = "Edit and publish your themes";
        form = (
          <WrappedLoginForm
            handleSubmit={this.handleSubmit}
            showForgotPassword={() => this.props.showAuthFlowStep('FORGOT_PASSWORD')}
            showSignUp={() => this.props.showAuthFlowStep('SIGN_UP')}
          />
        );
        break;
      case 'SIGN_UP':
        header2 = "Sign Up";
        sub2 = "Create a free account to publish themes!";
        form = (
          <WrappedSignUpForm
            handleSubmit={this.handleSubmit}
            showLogin={() => this.props.showAuthFlowStep('LOGIN')}
          />
        );
        break;
      case 'CONFIRM_CODE':
        header2 = "Confirm your Verification Code";
        sub2 = "Your final step to creating your account!";
        form = (
          <WrappedConfirmCodeForm
            handleSubmit={this.handleSubmit}
            resendCode={this.resendCode}
          />
        );
        break;
      case 'FORGOT_PASSWORD':
        header = (
          <Icon
            className="headerIcon"
            type="arrow-left"
            style={{color: "rgba(0,0,0,.45)"}}
            onClick={() => this.props.showAuthFlowStep('LOGIN')}
          />
        );
        header2 = "Forgot your Password?";
        sub2 = "Enter your username and we'll email you a reset code";
        form = (
          <WrappedForgotPasswordForm
            handleSubmit={this.handleSubmit}
          />
        );
        break;
      case 'REPLACE_PASSWORD':
        header2 = "Replace your Password";
        form = (
          <WrappedReplacePasswordForm
            handleSubmit={this.handleSubmit}
          />
        );
        break;
      default:
    }

    return (
      <Modal
        visible={showModal}
        onCancel={(e) => this.handleClose(this.props.authStep)}
        title={header}
        footer={footer}
        className = "authModal"
      >
        {additionalContent}
        <div className = "headerContent">
          {(header2) ? <h4>{header2}</h4> : null}
          {(sub2) ? <p>{sub2}</p> : null}
        </div>
        {form}
      </Modal>
    );
  }
}

export default AuthFlowModal;
