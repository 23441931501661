import React, { Component } from 'react';

import FontPicker from "font-picker-react";

import ColorSelect from '../contentComponents/ColorSelect'


class ColorModal extends Component {

  render() {

    const colorOptions = ["dark", "light", "success", "warning", "danger", "info"]

    let colorChoices = []

    colorOptions.map(option => {
      colorChoices.push(<div key={option} className="colorElement">
            <ColorSelect colorfun={this.props.colorValueChange} type={option} color={this.props.color[option]} />
              <p>{option} color</p>
            </div>)
    })

    return (
            <div className = "colorContainerModal">
              <h5>Your Color Pallette</h5>
              <p className = "colorSubtitle">Edit colors and use them in your components</p>
              {colorChoices}
            </div>
    );
  }
}

export default ColorModal;