import React, { Component } from 'react';

// Material UI Stuff
import Typography from '@material-ui/core/Typography';

// Key Value Map (Attribute to Input Types)
import ObjectMap from '../keyValue/cssMap.js';

// Import Components
import InputTypeCreator from './InputTypeCreator.js';

// Lodash stuff
import cloneDeep from 'lodash/cloneDeep';

// Immutability
import update from 'immutability-helper';

import { Row } from 'antd';


class InputType extends Component {


  // Loads the data for each CSS attribute into state
  constructor(props){
    super(props);

    if (this.props.attribute){

      let attrData = ObjectMap[this.props.attribute]

      this.state = {
        css: attrData
      }
    }

    this.updateComp = this.updateComp.bind(this)
  }

  // Calls props update function with the new values for the changed attribute
  updateComp(num, val) {

    let newState = update(this.state, {
      css: 
      {
        cssVals: 
        {
          [num]: 
          {
              val: { $set: val }
          }
        }
      }
    });

    this.setState(newState);

    this.props.updateComp(newState.css.cssVals, this.props.attribute);
  }

  // On load, push in the correct input type for each of the required attributes
  // This should also set initial state for them (Value)
  componentDidMount() {

    // This stores the components in state... Not efficient
    let attrInputs = [];

    // Start a new state to build for the initializing of variables
    let initState = ObjectMap[this.props.attribute]

    // ADD ALL OF THE INPUTS TO THE BLOCK
    Object.keys(this.state.css.cssVals).forEach((attrIndex) => {


      let dataDisplay = cloneDeep(this.state.css.cssVals[attrIndex])

      // If there is a manual override on the min/max values, then this does it here
      if(this.props.data && this.props.data[attrIndex]){
        dataDisplay.min = this.props.data[attrIndex].min
        dataDisplay.max = this.props.data[attrIndex].max
        dataDisplay.val = this.props.data[attrIndex].val
      }

      // If a default value has been passed, add it here
      if(typeof this.props.val !== "undefined"){
        dataDisplay.val = this.props.val[attrIndex].val
      }


      // UPDATE THE VALS IN THE STATE SUCH THAT THE LOADING DOESN'T GLITCH
      initState.cssVals[attrIndex].val = dataDisplay.val

      // ADD THE INPUT TO THE LIST OF INPUTS (SHOULD NOTTT BE DOING THIS IN STATE)

      attrInputs.push(<InputTypeCreator data={dataDisplay} updateComp={(x) => this.updateComp(attrIndex, x)} key={attrIndex} num={attrIndex}/>)
    });

    this.setState({
      css: initState,
      attrInputs: attrInputs
    });

  }

  componentDidUpdate(prevprops, newprops) {

    // Set the base value for the item
    let oldVal = this.props.val

    // attribute checks if the attribute has changed, data checks if the manual override has changed, ___ checks if the object has changed
    if (this.props.type !== prevprops.type) {

    // if ((prevprops.attribute !== this.props.attribute) || (this.props.data !== prevprops.data) || (this.props.type !== prevprops.type)) {

      let attrData = ObjectMap[this.props.attribute];

      let cssVals = attrData.cssVals

      let attrInputs = []

      // Start a new state to build for the initializing of variables
      let initState = ObjectMap[this.props.attribute] 

      // ADD ALL OF THE INPUTS TO THE BLOCK
      Object.keys(cssVals).forEach((attrIndex) => {

          let displayData = cssVals[attrIndex];

          // Gets the overrides from manual override
          if(this.props.data && this.props.data[attrIndex]){
            displayData.min = this.props.data[attrIndex].min
            displayData.max = this.props.data[attrIndex].max
            displayData.val = this.props.data[attrIndex].val
          }

          // Gets the overrides from state
        if(typeof oldVal !== "undefined"){
          displayData.val = oldVal[attrIndex].val
        }

        attrInputs.push(<InputTypeCreator data={displayData} updateComp={(x) => this.updateComp(attrIndex, x)} key={attrIndex} num={attrIndex} type = {this.props.type} />)

      });

      this.setState({
        attrInputs: attrInputs,
        css: attrData
      });
    }
  }

  render() {

    return (
      <div className="inputs">
        <h5>{this.state.css.title}</h5>
        <Row>
          {this.state.attrInputs}
        </Row>
      </div>
    );
  }
}

export default InputType;
