import React, { Component } from 'react';

import ElementOption from './elementOption'

// Key Value Map (Attribute to Input Types)
import Complist from '../keyValue/complist.js';

// Material UI Stuff
import Typography from '@material-ui/core/Typography';

// Lodash stuff
import cloneDeep from 'lodash/cloneDeep';

// Bootstrap Imports
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';

// Takes in CSS styling as props and applies it to the given element
class ElementSelector extends Component {
  constructor(props){
    super(props);
    this.state = {
      elements: Complist,
      key: "#button"
    };

    this.createNewEl = this.createNewEl.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // This is intended to add all existing elements to the "colors" list under that element such that
  // that exact element and color combo cannot be added again.
  componentDidMount() {

    let boxes = this.props.boxes;

    let elements = cloneDeep(this.state.elements);

    // Set it so that you can't add an element more than once
    Object.keys(boxes).forEach(key => {
      // indicates that one of the elements is in
      if (this.state.elements[boxes[key].type]) {
        elements[boxes[key].type].colors[boxes[key].color] = 1;
      }

    });

    this.setState({
      elements: elements
    });

  }

  componentDidUpdate(prevprops, prevstate){
    if(prevprops.elementToShow !== this.props.elementToShow){
      let tempKey = (typeof this.props.elementToShow === "undefined") ? ((typeof prevprops.elementToShow === "undefined" || prevprops.elementToShow == 0) ? "button" : prevprops.elementToShow) : this.props.elementToShow
      this.setState({
        key: ("#" + tempKey)
      });
    }
  }

  handleChange(attr){
    this.setState({
      key: attr
    });
  }

  createNewEl(type, color) {
    let elementsClone = cloneDeep(this.state.elements);

    elementsClone[type].colors[color] = 1;

    this.setState({
      elements: elementsClone
    });
    

    this.props.createNewEl(type, color);
  }

  render() {

    const classy = (this.props.visible === 2) ?
      "elementSelectorBack" :
      "elementSelectorBack hideme"

    let elements = this.state.elements;
    const createNewEl = this.createNewEl;

    // Holds the different category options (i.e. "Button", "card")
    let listOptions = []

    // Holds the elements to show for those categories (i.e. all the buttons.)
    let listElements = []

    // Store Props Colors
    let props = this.props

    let handleChange = this.handleChange;

    Object.keys(elements).forEach(function(attr) {

      let href = "#" + attr

      listOptions.push(
          <ListGroup.Item action href={href} key={attr} onClick={() => handleChange(href)}>
                  {attr}
          </ListGroup.Item>
      )

      let allowedColors = []

      let colors = []

      // If the base is enabled, add the base in here
      if(elements[attr].allowBase === true){
        colors.push("base");
      }

      // If colors are enabled, then add the colors in here
      if(elements[attr].allowColors === true){
        colors.push("primary", "secondary", "dark", "light", "success", "danger", "warning", "info")
      }

      // If the element is NOT in the state, push all colors
      if(!(attr in props.boxTypes)){
        colors.map(function(acolor){
          allowedColors.push(acolor);
        });
      } 

      // If it is in, then only push the colors that aren't already displayed
      else{ 

        const exitingColors = props.boxes[props.boxTypes[attr]].colors;

        colors.map(function(acolor){
          if(!(acolor in exitingColors)){
            allowedColors.push(acolor);
          }
        });

      }

      listElements.push(
        <ElementOption
          key={attr}
          type={attr}
          createNewEl={createNewEl}
          name={Complist[attr].name}
          color={allowedColors}
          eventKey = {href}
        />
      );

    });

    const bg = `rgba(${ this.props.backgroundColor.r }, ${ this.props.backgroundColor.g }, ${ this.props.backgroundColor.b }, ${ this.props.backgroundColor.a })`

    return (


     <div className={classy}>
       <div className="elementSelector" style = {{background: bg}}>

        <Tab.Container id="list-group-tabs-example" activeKey={this.state.key}>
          <Row>
            <Col sm={4}>
              <ListGroup className = 'myListGroup'>
                {listOptions}
              </ListGroup>
            </Col>
            <Col sm={8} className = "listElements">
              <Tab.Content>
                  {listElements}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
 
        </div>
        <div
          className="closer"
          onClick={this.props.hideElSelector}
        />
      </div>

    );
  }
}

export default ElementSelector;
