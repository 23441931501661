import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Importing Theming Tools
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// importing bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

// importing antd css
import 'antd/dist/antd.css';
import notification from 'antd/es/notification';

// Import the jank css
// Import CSS
import './css/MainContent.css';
import './css/jank.css';

import Amplify from 'aws-amplify';
import awsconfig from './aws-config';

Amplify.configure({
  Auth: {
    region: awsconfig.cognito.REGION,
    userPoolId: awsconfig.cognito.USER_POOL_ID,
    identityPoolId: awsconfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsconfig.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "cardlabApi",
        endpoint: awsconfig.apiGateway.URL,
        region: awsconfig.apiGateway.REGION
      },
    ]
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#DF6C50',
      dark: '#000'
    },
    secondary: {
      main: 'rgba(255,255,255,.8);'
    },
  },
  typography: {
    useNextVariants: true
  }
});

// antd notifications
notification.config({
  top: 70
});

ReactDOM.render(
  <MuiThemeProvider theme = { theme }>
    <App />
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
