import React from 'react'

import Dropdown from 'react-bootstrap/Dropdown';

class ColorSelect extends React.Component {

  render() {

    const items = []

    Object.keys(this.props.colorOptions).forEach(key => {
        if (key !== "background"){
          items.push(<Dropdown.Item key = {key} onClick = {() => this.props.updateActiveColor(key)}>{key}</Dropdown.Item>)          
        }

    });

    return (
        <Dropdown>
          <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
            {this.props.activeElement}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {items}
          </Dropdown.Menu>
        </Dropdown>
    );
  }
}

export default ColorSelect;