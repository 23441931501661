import React from 'react';

import Form from 'antd/es/form';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';

function ConfirmCodeForm(props) {
  const { getFieldDecorator } = props.form;

  function handleSubmit(event) {
    event.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.handleSubmit(values);
      }
    });
  }

  return (
    <Form
      onSubmit={handleSubmit}
    >
			<Form.Item style = {{marginBottom: 10}}>
				{getFieldDecorator('verificationCode', {
					rules: [{ required: true, message: 'Please input your code!' }],
				})(
					<Input
            size = "large" 
						prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
						placeholder="Enter your 6-digit code"
					/>,
				)}
			</Form.Item>
      <div className="forgotPass" onClick={props.showForgotPassword}>
        <div className = "myLink" onClick={props.resendCode}>Resend Code</div>
      </div>
      <button className="myButton tallBoi" type="submit">
        Confirm
      </button>
    </Form>
  );
}

const WrappedConfirmCodeForm = Form.create({ name: 'confirm_code_form' })(ConfirmCodeForm);
export default WrappedConfirmCodeForm;
