import React, { Component } from 'react';

import CloseButton from './CloseButton'

import DragIcon from '../img/drag.svg'

class CardBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMouseDown:false,
      mouseX: null,
      mouseY: null,
      elementX:0,
      elementY:0
    };

    this.handleUp = this.handleUp.bind(this);
    this.handleDown = this.handleDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }

  handleUp(event) {
    this.setState({
      isMouseDown: false
    });
  }

  handleDown(event) {
    this.setState({
      mouseX: event.clientX,
      mouseY: event.clientY,
      isMouseDown: true
    });
  }

  onMouseMove(event) {
    if (!this.state.isMouseDown) return;

    var deltaX = event.clientX - this.state.mouseX;
    var deltaY = event.clientY - this.state.mouseY;

    this.setState({
        mouseX: event.clientX,
        mouseY: event.clientY
    });

    this.props.updateLoc(deltaX, deltaY);
  }


  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
  }

  render() {
    // what is this??
    const classy = this.state.isMouseDown ? "dragme dragged" : "dragme";

    return (
      <div className="cardBar">
        <div
          className={classy}
          onMouseDown={this.handleDown}
          onMouseUp={this.handleUp}
        ></div>
        <img alt="Drag Icon" src={DragIcon} className="dragIcon" />
        <CloseButton close={this.props.hideMo} />
      </div>
    );
  }
}

export default CardBar;
