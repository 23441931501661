import React from 'react';

import { Slider, InputNumber, Row, Col } from 'antd';

class InputSlider extends React.Component {

  constructor(props){
    super(props);
    this.state = {
    inputValue: this.props.init,
    };
  }

  onChange = value => {

    console.log("IM BEING CHANGED")
    console.log(value)

    this.setState({
      inputValue: value,
    });
    this.props.updateMe(value, this.props.num);
  };

  componentDidUpdate(prevprops){
    if(prevprops.type !== this.props.type){
      this.setState({
        inputValue: this.props.init,
      })
      this.onChange(this.props.init)
    }
  }

  render() {

    const { inputValue } = this.state;
    return (
      <Row>
        <Col span={19}>
          <Slider

            min={this.props.min}
            max={this.props.max}
            onChange={this.onChange}
            value={typeof inputValue === 'number' ? inputValue : 0}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            className = "myInputBox"
            min={this.props.min}
            max={this.props.max}
            style={{ marginLeft: 16 }}
            value={inputValue}
            onChange={this.onChange}
          />
        </Col>
      </Row>
    );
  }
}

export default InputSlider;