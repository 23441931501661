import React, { Component } from 'react';
// Import Components
import Grid from '@material-ui/core/Grid';

import contentEditable from '../utilityFiles/contentEditable'

class ColorElements extends Component {
  render() {


    let EditableH1 = contentEditable('h1');

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} className = "titleBox" >
          <EditableH1 updateTitle = {this.props.updatetitle} value={this.props.meta.title}/>
        </Grid>
      </Grid>
    );
  }
}

export default ColorElements;


// UPDATE THE LEFTCARD SECTION SUCH THAT IT PASSES THROUGH A ELEMENT THAT I FROM WHAT IT CLICKS!!!


// Setting CSS color
// Primary color: background-color: var(--primary);

// <p>By {this.props.meta.author}</p>