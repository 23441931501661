import React, { Component } from 'react';

// Import Components
import { Container, Grid } from '@material-ui/core';

import CompElementContainer from './CompElementContainer';
import AddElement from './AddElement';
import TextElement from './TextElement';
import ColorElements from './ColorElements';
import TitleBox from './TitleBox';

class MainContent extends Component {

  render() {

    const box = [];
    
    Object.keys(this.props.css.boxes).forEach(key => {
      box.push(
        <CompElementContainer
          key={key}
          css={this.props.css.boxes[key]}
          colors={this.props.css.boxes[key].colors}
          showMo={() => this.props.showMo(key)}
          showElSelector={() => this.props.showElSelector(2, this.props.css.boxes[key].type)}
          num={key}
        />
      );
    });

    return (
      <div className="mainContent" id="mainContent" >
        <Container maxWidth="lg" className="mainContentContainer apply-font" >
          <TitleBox meta = {this.props.meta} updatetitle = {this.props.updateTitle}/>

          <Grid container spacing={3} style={{paddingTop: 30}}>
            <Grid item xs={7} md={7} lg={7} className="colorContainer">
                <div className="positioner">
                  <h5 className="title" style={{width: 150}}>Theme Colors</h5>
                </div>
                <ColorElements
                  updated = {this.props.updated}
                  colorfun={this.props.colorValueChange}
                  color={this.props.css.color}
                  showElSelector={this.props.showElSelector}
                />
            </Grid>
            <Grid item xs={5} md={5} lg={5} className="colorContainer">
                  <TextElement textCss = {this.props.css.text} updateText = {this.props.updateText} />
              </Grid>
            </Grid>
          
          <Grid container spacing={3} style = {{paddingTop: 30}}>
            <Grid item xs={12} md={12} lg={12} className="colorContainer" style={{paddingBottom: 60}}>
              <h5 className="title" >Theme Components</h5>
            </Grid>
              {box}
            <AddElement showElSelector={() => this.props.showElSelector(2)} />
          </Grid>
        </Container>
      </div>
    );
  }
}

export default MainContent;


// UPDATE THE LEFTCARD SECTION SUCH THAT IT PASSES THROUGH A ELEMENT THAT I FROM WHAT IT CLICKS!!!
