import React, { useState } from 'react';

import Form from 'antd/es/form';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';


const CheckRow = function(props) {
  const myClass = props.active ? "activeCheck" : ""
  const myClass2 = props.active ? "activeText" : ""
    return (
    <div className = "passRow">
      <div className = {"checkDiv " + myClass}>
        <svg class="checkbox-tick" viewBox="0 0 30 30" data-reactid=".0.1.2.0.a.1.0.2.0.0.1.0.0"><path d="M13.08,16.53l-2.08-2a1.7,1.7,0,0,0-2.48,0,1.88,1.88,0,0,0,0,2.59L12,20.46a1.71,1.71,0,0,0,1.27.54,1.75,1.75,0,0,0,1.3-.55l6.91-8.23a2,2,0,0,0,0-2.66,1.73,1.73,0,0,0-2.54,0l-5.85,7h0Z" data-reactid=".0.1.2.0.a.1.0.2.0.0.1.0.0.0"></path></svg>
      </div>
      <p className = {myClass2}>{props.text}</p>
    </div>
    )
}


function SignUpForm(props) {
  const { getFieldDecorator } = props.form;

  const [pwLengthCheck, setPwLengthCheck] = useState(false);
  const [pwLowercaseCheck, setPwLowercaseCheck] = useState(false);
  const [pwUppercaseCheck, setPwUppercaseCheck] = useState(false);
  const [pwNumberCheck, setPwNumberCheck] = useState(false);
  const [pwSpecialCheck, setPwSpecialCheck] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.handleSubmit(values);
      }
    });
  }

  function validatePassword(rule, value, callback) {
    console.log(value)
    if (!value) {
      callback();
    }

    const lengthPass = value.length >= 8;
    const lowercasePass = value.match(/^.*(?=.*[a-z]).*$/) ? true : false;
    const uppercasePass = value.match(/^.*(?=.*[A-Z]).*$/) ? true : false;
    const numberPass = value.match(/^.*(?=.*\d).*$/) ? true : false;
    const specialPass = value.match(/^.*(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/\\,><':;\|_~`]).*$/) ? true : false;

    setPwLengthCheck(lengthPass);
    setPwLowercaseCheck(lowercasePass);
    setPwUppercaseCheck(uppercasePass);
    setPwNumberCheck(numberPass);
    setPwSpecialCheck(specialPass);

    if (!lengthPass || !lowercasePass || !uppercasePass || !numberPass || !specialPass) {
      callback('Password does not meet criteria');
    } else {
      callback();
    }
    //if (value && (value.length < 8 || !value.match(pwPattern))) {
      //callback('Password does not meet criteria. You must include: 1) At least 8 characters 2) At least 1 lowercase letter 3) At least 1 uppercase letter 4) At least 1 number 4) At least 1 special character out of ^$*.[]{}()?-"!@#%&/\\,><\':;|_~`');
    //} else {
      //callback();
    //}
  }

  return (
    <Form
      onSubmit={handleSubmit}
    >
      <Form.Item>
				{getFieldDecorator('username', {
					rules: [{ required: true, message: 'Please input your username!' }],
				})(
					<Input
						size = "large"
						prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
						placeholder="Username"
					/>,
				)}
      </Form.Item>
			<Form.Item>
				{getFieldDecorator('email', {
          rules: [
            { type: 'email', message: 'The input is not valid Email!' },
            { required: true, message: 'Please input your Email!' }
          ],
				})(
					<Input
						size = "large"
						prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
						placeholder="Email"
					/>,
				)}
			</Form.Item>
			<Form.Item className = "signupOption">
				{getFieldDecorator('password', {
          rules: [
            { required: true, message: 'Please input your Password!' },
            { validator: validatePassword }
          ],
				})(
          <Input
            size = "large"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
				)}
			</Form.Item>
      <div className="passwordFooter">
          <p>Password must include:</p>
          <CheckRow text="At least 8 characters" active={pwLengthCheck} type="close" />
          <CheckRow text="At least 1 lowercase letter" active={pwLowercaseCheck} type="close" />
          <CheckRow text="At least 1 uppercase letter" active={pwUppercaseCheck} type="close" />
          <CheckRow text="At least 1 number" active={pwNumberCheck} type="close" />
          <CheckRow text={'At least 1 special character'} active={pwSpecialCheck} type="close" />
      </div>
      <button className ="myButton tallBoi" type = "submit" style = {{marginBottom: 20}} >
        Sign Up
      </button>
      <div className = "customFooter">
      	<p>Already have an account? <em onClick={props.showLogin}>Log in now!</em></p>
      </div>
    </Form>
  );
}

const WrappedSignUpForm = Form.create({ name: 'sign_up_form' })(SignUpForm);
export default WrappedSignUpForm;
