import React, { Component } from 'react';




	// ALERT
	const alertBody = "This is a sample alert!" 
	
	// BADGE
	const badgeBody = "Badge";

	// BUTTON
	const buttonBody = "Button";

	// CARD
	const cardBody = (<div className="card-body">
						    <h5 className="card-title">Card title</h5>
						    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
						  </div>);

	// CARD
	const inputBody = (<input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"></input>);

	// Modal
	const modalBody = (<div className="modal-dialog" role="document">
						    <div className="modal-content">
						      <div className="modal-header">
						        <h5 className="modal-title">Modal title</h5>
						        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
						          <span aria-hidden="true">&times;</span>
						        </button>
						      </div>
						      <div className="modal-body">
						        <p>Modal body text goes here.</p>
						      </div>
						      <div className="modal-footer">
						        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
						        <button type="button" className="btn btn-primary">Save changes</button>
						      </div>
						    </div>
						  </div>);

	// NAVBAR
	const navbarBody = ([<a key={1} className="navbar-brand" href='#0'>Navbar</a>,
						 <button key={2} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						    <span className="navbar-toggler-icon"></span>
						 </button>,
						  <div key={3} className="collapse navbar-collapse" id="navbarNav">
						    <ul className="navbar-nav">
						      <li className="nav-item active">
						        <a className="nav-link" href='#0'>Home <span className="sr-only">(current)</span></a>
						      </li>
						      <li className="nav-item">
						        <a className="nav-link" href='#0'>Features</a>
						      </li>
						      <li className="nav-item">
						        <a className="nav-link" href='#0'>Pricing</a>
						      </li>
						      <li className="nav-item">
						        <a className="nav-link disabled"  tabIndex="-1" aria-disabled="true" href='#0'>Disabled</a>
						      </li>
						    </ul>
						  </div>])

	// Create a dict with all of these variables so that returning is easy
	const returnDict = {
		"alert": alertBody,
		"badge": badgeBody,
		"button": buttonBody,
		"card": cardBody,
		"input": inputBody,
		"modal": modalBody,
		"navbar": navbarBody
	};


// This file contains the contents of each component. That means that this can just be loaded from the component and plugged into its body
// The naming convention will be *the type of the component* + "body"
export default function componentBody(name) {

	return(returnDict[name]);
}


	// const listBody = ([<li className="list-group-item active">Cras justo odio</li>,
	// 				  <li className="list-group-item">Dapibus ac facilisis in</li>,
	// 				  <li className="list-group-item">Morbi leo risus</li>,
	// 				  <li className="list-group-item">Porta ac consectetur ac</li>]);

	// // CARD
	// const inputBody = ([<label for="exampleInputEmail1">Email address</label>,
	// 				    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"></input>,
	// 				    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>]);
