import React, { Component } from 'react';
import styled from 'styled-components';

// Key Value Map
import Objectmap from '../keyValue/typeMap.js';

// Import Components
import Grid from '@material-ui/core/Grid';

import componentBody from './allComponents/loadComponents.js'
import ComponentBadgeMenu from './ComponentBadgeMenu.js'


// MAKE THIS MORE EFFICIENT!!!!
// DEFINATELY A BETTER WAY TO DO THIS!!!
// https://medium.com/@pitipatdop/10-useful-tips-for-styled-components-b7710b021e6a




//element types: div, span, button, nav (can pass via props)



// Create all the CSS Styles
// INPUT: An object of CSS values, with styling choices enumerated
//     {
//      border-radius: {0: 100}
//     }
//
// OUTPUT: A formatted string of the correct CSS
//     border-radius: 100px;
//
const cssGenerator = (obj) => {
  let myStr = ``

  // LOOPS THROUGH EACH ATTRIBUTE (i.e. border radius)
  Object.keys(obj).forEach(function(attr) {

        myStr += `${attr}: `

        // Loops through each prop of each attribute (i.e. {0: 100})
        Object.keys(obj[attr]).forEach(function(num) {
          if (obj[attr][num].type === "text") {

            if (obj[attr][num].values[obj[attr][num].val] !== "normal"){
              myStr += `${obj[attr][num].values[obj[attr][num].val]}`;
            }
          } else if (obj[attr][num].type === "colorPicker") {

            let temp = obj[attr][num].val

            myStr += `rgba(${temp.r}, ${temp.g}, ${temp.b}, ${temp.a})`;

          } else {

            // Add the value (i.e. 100)
            myStr += `${obj[attr][num].val}`;
          }
          // Add the ending (i.e. "px")
          myStr += `${Objectmap[attr][num]} `;
        })

        // Prepare for the next CSS
        myStr += `;`;

  })

  return myStr;
}


//Function to assign the value of the styledcomponents component
function StyledDiv(type) {
  const Div = styled(type)`
    ${(props) =>  cssGenerator(props.css)}
  `;

  return(Div)
}

// Takes in CSS styling as props and applies it to the given element
class CompElement extends Component {

  objToCSS(data) {
    let temp = ``
    Object.keys(data).forEach(function(key) {
       temp += key + ': ' + data[key] + ';'
    });
    return temp
  }

  render() {

    // This gets the body contents of the element
    const children = componentBody(this.props.css.type);

    // This creates the css class for the elements
    const colorOption = (this.props.css.color !== "base") ? (this.props.css.classColor + this.props.color) : ""

    const elementClass = this.props.css.cssClass + " " + colorOption + " " + this.props.css.addClass;

    const CorrectDiv = StyledDiv(this.props.css.divType)

    const element = <CorrectDiv css={this.props.css.css} className={elementClass}>{children}</CorrectDiv>

    return (
          <div className = {"componentContainer " + "componentContainer" + this.props.css.cssClass}>
          {element}
          </div>
    );
  }
}

export default CompElement;


        // {this.state.displayColorOption ? <ComponentBadgeMenu colorOptions = {this.props.color} activeElement = {this.state.activeElement} updateActiveColor = {this.updateActiveColor} /> : <div/>}


