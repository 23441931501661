
// A function I found on the internet that shades colors (from the psbc library on github)

// export const RGB_Linear_Shade=(p,c)=>{
//     var i=parseInt,r=Math.round,[a,b,c,d]=c.split(","),P=p<0,t=P?0:255*p,P=P?1+p:1-p;
//     return"rgb"+(d?"a(":"(")+r(i(a[3]==="a"?a.slice(5):a.slice(4))*P+t)+","+r(i(b)*P+t)+","+r(i(c)*P+t)+(d?","+d:")");
// }
// https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
export const RGB_Linear_Shade2=(p,c)=>{

    var i = parseInt,
    r = Math.round,
    [a,b,c,d] = [c.r, c.g, c.b, c.a],
    P=p<0,t=P?0:255*p,
    P=P?1+p:1-p;

	return ({
			r: (r(i(a*P+t))),
			g: r(i(b)*P+t),
			b: r(i(c)*P+t),
			a: d
		}) 
}


// A function that inputs a color, and outputs the appopriate text color for that background
export const colorTextShader = (color) => {
	let temp = (color.r * 299) + (color.g * 587) + (color.b * 114) / 1000;
	
	if (temp >= 120000) {
    	return({r: 17, g: 17, b: 17, a: 1});
	} else {
    	return({r: 255, g: 255, b: 255, a: 1});
  	}

}


// A function that converts a dictionary of rgba objects to a string
// 0 gives full rgba
// 1 gives the rgb exclusively
export const rgba2string = (rgba ,type) => {
	if (type === 1){
		return (rgba.r + ", " + rgba.g + ", " + rgba.b)
	} else {
		return (`rgba(${ rgba.r }, ${ rgba.g }, ${ rgba.b }, ${ rgba.a })`)
	}
}


// ALL OF THIS IS FOR COLOR LEVEL

// Shade the theme here:
export const themeColorLevel = (color, level) => {
	// const colorBase = (level > 0) ? {r: 0, g: 0, b: 0, a: 0} : {r: 255, g: 255, b: 255, a: 255}
	level = Math.abs(level);
	// const themeColorInterval = .08;
	// return RGB_Linear_Shade2(colorBase, rgb2hex(color), Math.round(level * themeColorInterval) * 10);
  return RGB_Linear_Shade2(color, level);
}














// Testing stuff here. Ignore for now.

// const mix = (color1, color2, weight) => {
// 	return({
// 		r: (1 - weight) * color1.r + weight * color2.r, 
// 	    g: (1 - weight) * color1.g + weight * color2.g, 
// 	    b: (1 - weight) * color1.b + weight * color2.b,
// 	    a: 1
// 	})
// }

// const mix = (color_1, color_2, weight) => {

// 	console.log(color_1)
// 	console.log(color_2)
// 	console.log(weight)

//   function d2h(d) { return d.toString(16); }  // convert a decimal value to hex
//   function h2d(h) { return parseInt(h, 16); } // convert a hex value to decimal 

//   weight = (typeof(weight) !== 'undefined') ? weight : 50; // set the weight to 50%, if that argument is omitted

//   var color = "#";

//   for(var i = 0; i <= 5; i += 2) { // loop through each of the 3 hex pairs—red, green, and blue
//     var v1 = h2d(color_1.substr(i, 2)), // extract the current pairs
//         v2 = h2d(color_2.substr(i, 2)),
        
//         // combine the current pairs from each source color, according to the specified weight
//         val = d2h(Math.round(v2 + (v1 - v2) * (weight / 100.0))); 

//     while(val.length < 2) { val = '0' + val; } // prepend a '0' if val results in a single digit
    
//     color += val; // concatenate val to our new color string
//   }


//   console.log(color);
//   return color; // PROFIT!

// };

// const rgb2hex = (rgb) => {

//   function hex(x) { return ("0" + parseInt(x).toString(16)).slice(-2); } // another way to convert a decimal to hex
  
//   return (hex(rgb.r) + hex(rgb.g) + hex(rgb.b)).toUpperCase(); // concatenate the pairs and return them upper cased
// };





