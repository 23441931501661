import React, { Component } from 'react';

// Import Components
import Grid from '@material-ui/core/Grid';

import FontPicker from "font-picker-react";

import ColorSelect from './ColorSelect'



class TextElement extends Component {

  constructor(props){
    super(props);

    this.state = {
      activeFontFamily: "Roboto",
    }

    this.setNewFont = this.setNewFont.bind(this)
  }

  setNewFont(fontName) {
    this.setState({
      activeFontFamily: fontName
    })
  }

  componentDidUpdate(prevprops){

    if(this.props.textCss.fontFamily !== prevprops.textCss.fontFamily){
        this.setState({
          activeFontFamily: this.props.textCss.fontFamily
        });
    }

  }

  render() {

    return (
      <div>
        <div className="positioner">
          <h5 className="title" style={{width: 160}}>Text Color & Font</h5>
        </div>
        <div className = "colorBody" >


              <Grid container>
                    <Grid item xs={4} md={4} lg={4}>
                      <div key="fontFamily" className="fontElement">
                        <FontPicker
                          apiKey="AIzaSyAferoR1a0vspBDQ2xmDnxajbA56My_t5I"
                          activeFontFamily={this.state.activeFontFamily}
                          setNewFont = {this.setNewFont}
                          onChange={nextFont => {
                                                this.setNewFont(nextFont.family)
                                                this.props.updateText("font", nextFont.family)
                                                }
                          }
                        />
                        <p>Theme Font</p>
                      </div>
                    </Grid>
              <Grid item xs={8} md={8} lg={8}>
              </Grid>
            </Grid>


        </div>
      </div>
    );
  }
}

export default TextElement;

              // <div key="textColor" className="colorElement">
              //     <ColorSelect colorfun={(x,y) => this.props.updateText("color", y)} type={"type"} color={{r: 0, g: 0, b: 0, a: 1}} />
              //     <p>Dark Text Color</p>
              //   </div>
               // <div key="textColor2" className="colorElement">
               //    <ColorSelect colorfun={(x,y) => this.props.updateText("color", y)} type={"type"} color={{r: 0, g: 0, b: 0, a: 1}} />
               //  <p>Light Text Color</p>
               //  </div>

