import React, { Component } from 'react';

// Bootstrap Stuff
import InputType from './InputType'
import CardBar from './CardBar'
import TextModalContent from './TextModalContent'
import ColorModal from './ColorModalContent'

// Key Value Map (Attribute to Input Types)
import Attrmap from '../keyValue/attrMap.js';

// Import CSS
import '../css/MainCard.css';

class MainCard extends Component {
  constructor(props) {
    super(props);

    this.state = { left: 100, top: 100, activeFontFamily: "Roboto" };

    if (this.props.css.type) {
      this.state.elements = Attrmap[this.props.css.type];
      this.state.type = this.props.css.type;
    } else {
      this.state.elements = {};
    }

    this.updateLoc = this.updateLoc.bind(this);
    this.setNewFont = this.setNewFont.bind(this);
  }

  updateLoc(x, y) {
    this.setState({
      left: this.state.left + x,
      top: this.state.top + y
    });
  }

  setNewFont(fontName) {
    this.setState({
      activeFontFamily: fontName
    })
  }

  // If there has been an update as in the type of content being edited is different
  // If we decide to split components (button outline, button dash), this might have to be updated
  componentDidUpdate(prevprops, prevstate){

    if (this.props.css.type !== prevprops.css.type) {
      this.setState({
        elements: Attrmap[this.props.css.type],
        type: this.props.css.type
      });
    } 
  }

  render() {

    const inputs = []

    if(this.props.visible === 1){

      inputs.push(Object.keys(this.state.elements).map((attr) => {

                  let val = (this.props.css.css[this.state.elements[attr].type]) ? this.props.css.css[this.state.elements[attr].type] : undefined;

                  return (<InputType key={attr} 
                                              attribute={this.state.elements[attr].type} 
                                              data={this.state.elements[attr].manualOver} 
                                              updateComp={this.props.updateComponent} 
                                              type={this.state.type} 
                                              val = {val}/>)}
                ));
    } else if (this.props.visible === 3){

      inputs.push(<TextModalContent  
                                  key = {99}
                                  updateText = {this.props.updateText} 
                                  textValues = {this.props.textValues} 
                                  activeFontFamily = {this.state.activeFontFamily} 
                                  setNewFont = {this.setNewFont}/>)

    } else {

      inputs.push(<ColorModal key={99}
                           colorValueChange={this.props.colorValueChange} 
                           color={this.props.color} />)

    }
      // ((this.props.visible === 3) ?  : 


    const showMe = (this.props.visible === 1 || this.props.visible === 3 || this.props.visible === 4) ? "mainCardContainer" : "mainCardContainer hideMe";

    return (
      <div className={showMe} 
           style={{ left: this.state.left, top: this.state.top }}>

        <CardBar hideMo={this.props.hideMo} 
                 updateLoc={this.updateLoc}/>

          <div className="mainCard">
            <div className="sliderContainer">
                {inputs}
            </div>
        </div>
      </div>
    );
  }
}

export default MainCard;

