import React, { Component } from 'react';
// Import Components
import Grid from '@material-ui/core/Grid';

import ColorSelect from './ColorSelect'

import { Button } from 'antd';

class ColorElements extends Component {
  render() {
    // MAKE THIS NOT RE-RENDER
    // const otherElements = []
    // let colorfun = this.props.colorfun

    // Object.keys(this.props.color).forEach(key => {
    //   console.log(key)
    //   console.log(this.props.color[key])
    //   otherElements.push(
    //     <div key={key} className="colorElement">
    //       <ColorSelect colorfun={colorfun} type={key} color={this.props.color[key]} />
    //       <p>{key}</p>
    //     </div>
    //   );
    // });

    if (this.props.updated === 0){
      return(<div />)
    }

    return (
          <div className="colorBody">
            <div key={"background"} className="colorElement">
              <ColorSelect colorfun={this.props.colorfun} type="background" color={this.props.color["background"]} />
              <p>Background Color</p>
            </div>
            <div key={"primary"} className="colorElement">
              <ColorSelect colorfun={this.props.colorfun} type="primary" color={this.props.color["primary"]} />
              <p>Primary Color</p>
            </div>
            <div key={"secondary"} className="colorElement">
              <ColorSelect colorfun={this.props.colorfun} type="secondary" color={this.props.color["secondary"]} />
              <p>Secondary Color</p>
            </div>
            <Button icon = "plus" style = {{height: 48}} onClick = {() => this.props.showElSelector(4)} >More colors</Button>
          </div>
    );
  }
}

export default ColorElements;


// UPDATE THE LEFTCARD SECTION SUCH THAT IT PASSES THROUGH A ELEMENT THAT I FROM WHAT IT CLICKS!!!


// Setting CSS color
// Primary color: background-color: var(--primary);

// ADDITIONAL COLOR STUFF HERE!!!
            // <div key={999}  className="colorElement">
            //   <AddColor addColor={this.props.addColor} />
            // </div>