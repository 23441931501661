// Stores a list of attribute along with input type
// Called from MainCard.js

// Requirements
// "ELEMENT NAME": {
// 		0 (input position): {
//				type: "css name"
//				manualOver: values that override the template requirements
//         }
// }

// TO ADD A NEW ELEMENT, YOU MUST:
// 1) Add it here
// 2) Add it in the compElement.js
export default {

  "alert": {
    0: {
      type: "border-radius",
      manualOver: {
        0: {
          min: 0,
          max: 15,
          val: 5
        }
      }
    },
    1: {
      type: "box-shadow"
    },
    2: {
      type: "padding"
    },
    3: {
      type: "font-size"
    },
    4: {
      type: "border"
    }
  },

  "badge": {
    0: {
      type: "border-radius",
      manualOver: {
        0: {
          min: 0,
          max: 15,
          val: 5
        }
      }
    },
    1: {
      type: "box-shadow"
    },
    2: {
      type: "padding"
    },
    3: {
      type: "font-size"
    },
    4: {
      type: "border"
    }
  },

	"button": {
    0: {
      type: "border-radius",
      manualOver: {
        0: {
          min: 0,
          max: 30,
          val: 4
        }
      }
    },
    1: {
      type: "box-shadow"
    },
    2: {
      type: "padding",
      manualOver: {
        0: {
          min: 0,
          max: 40,
          val: 6
        }
      }
    },
    3: {
      type: "font-size"
    },
    4: {
      type: "border"
    }
  },

  "card": {
    0: {
      type: "border-radius",
      manualOver: {
        0: {
          min: 0,
          max: 90,
          val: 4
        }
      }
    },
    1: {
      type: "box-shadow"
    },
    2: {
      type: "border"
    }
  },

  "input": {
    0: {
      type: "box-shadow"
    },
    1: {
      type: "border"
    },
    2: {
      type: "height"
    },
    3: {
      type: "font-size"
    }
  },

  "list": {
    0: {
      type: "box-shadow"
    },
    1: {
      type: "border"
    },
    2: {
      type: "height"
    },
    3: {
      type: "font-size"
    }
  },

  "modal": {
    0: {
      type: "box-shadow"
    },
    1: {
      type: "border"
    },
    2: {
      type: "height"
    },
    3: {
      type: "font-size"
    }
  },

  "navbar": {
    0: {
      type: "box-shadow"
    },
    1: {
      type: "border"
    },
    2: {
      type: "height"
    },
    3: {
      type: "font-size"
    }
  },
};
