import React, { Component } from 'react';

// Import Components
import TextInput from './inputComponents/TextInput.js';
import SliderInput from './inputComponents/SliderInput.js';
import ColorSelect from '../contentComponents/ColorSelect.js';

// Color Picker
// import ColorPicker from 'rc-color-picker';
// import 'rc-color-picker/assets/index.css';

import { Col } from 'antd';


class InputTypeCreator extends Component {

  constructor(props){
    super(props);
    this.colorChange = this.colorChange.bind(this);
  }

  colorChange(type, color){
    this.props.updateComp(color)
  }

  render() {
    if (this.props.data.type === "colorPicker") {
      return (
        <Col span={4} key={this.props.num} className="colorPicker" style={{marginTop: 6}} >
          <ColorSelect colorfun = {this.colorChange} type="background" color={{r:0,b:0,g:0,a:1}} />
        </Col>
      );
    } else if (this.props.data.type === "slider") {
      return (
        <Col span={24}  key={this.props.num} >
          <SliderInput
            min={this.props.data.min}
            max={this.props.data.max}
            init={this.props.data.val}
            updateMe={this.props.updateComp}
            type={this.props.type}
          />
        </Col>
      );
    } else if (this.props.data.type === "text") {
      return(
        <Col span={8}  key={this.props.num} style={{marginTop: 6}}>
          <TextInput
            values={this.props.data.values}
            init={this.props.data.val}
            updateMe={this.props.updateComp}
          />
        </Col>
      );
    } else {
      return (<div />);
      }
    }
}

export default InputTypeCreator;


// ColorPicker
//             name='color'
//             defaultValue='#000'
//             // value={this.state.color} - for controlled component
//             onChange={color => {if(color){this.props.updateComp(color)}}}
//           />