import React from 'react';
import './App.css';
import BannerImage from './BannerImage';
import Mail from './mail.svg';
import CurveSection from './curveSection.svg';
import CurveSection2 from './curveSection2.svg';
import ThemePic from './ThemePic';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import { Layout, Menu, Breadcrumb, Icon, Row, Col } from 'antd';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

function Homepage(props) {

  const loginButt = !props.isAuthenticated ?
    (
      <div
        className="whiteButt navButt"
        onClick={() => props.showAuthFlowStep('LOGIN')}
      >
        <div className="center">
          Login
        </div>
      </div>
    ) :
    null;

  const themePics = [<ThemePic isAuthenticated = {props.isAuthenticated}
                               title = "Marvel"
                               primary = "#1FB6FF"
                               secondary = "#13CE66"
                               light = "#F9FAFC"
                               dark = "#3C4858"
                               showPubLogin={() => props.showAuthFlowStep('PUBLOGIN')}
                               publish = {props.publish}
                    />,
                    <ThemePic  isAuthenticated = {props.isAuthenticated}
                               title = "Smooth"
                               primary = "#335eea"
                               secondary = "#506690"
                               light = "#f9fbfd"
                               dark = "#2b354f"
                               showPubLogin={() => props.showAuthFlowStep('PUBLOGIN')}
                               publish = {props.publish}

                    />,
                    <ThemePic  isAuthenticated = {props.isAuthenticated}
                               title = "Uber"
                               primary = "#000"
                               secondary = "#276EF1"
                               light = "#fff"
                               dark = "#000"
                               showPubLogin={() => props.showAuthFlowStep('PUBLOGIN')}
                               publish = {props.publish}

                    />,
                    ]

  const loginout =  (props.isAuthenticated) ?
                    <Menu.Item
                      className="myMenuItem"
                      onClick={props.handleLogout}
                      key="1"
                    >
                      Log Out
                    </Menu.Item> :

                    <Menu.Item
                      className="myMenuItem"
                      onClick={() => props.showAuthFlowStep('LOGIN')}
                      key="1"
                    >
                      Log In
                    </Menu.Item>

  

  return (
    <div className="App">

  <Layout>

    <Header className = "contained myheader">
      <div className="logo">themelab</div>
      <Menu
        mode="horizontal"
        // defaultSelectedKeys={['2']}
        style={{ lineHeight: '64px', float: "right" }}
      >
      {loginout}
      </Menu>

    </Header>
    <div className = "headerBack" />
    <div className = "sectionBack" />

    <Content style={{ padding: '50px 50px' }} className = "contained headerB">

      <div className = "headerBox">
        <Row>
          <Col xs={10} sm={10} md={10} lg={10} xl={10} className = "holdText">
            <div className="textHolder">
            <h1>Make your website look great with one line of code</h1>
            <h5>themelab is an easy tool to quickly customize bootstrap and iterate on design.</h5>
            <div className = "buttonContainer">
              <Link to={"/editor"}><div className = "myButton" style={{marginRight: "20px"}}>Create a Theme!</div></Link>
              <div className = "myButton greenBoi" onClick = {props.showPubModal} >How does it Work?</div>

            </div>
            </div>
          </Col>
          <Col xs={14} sm={14} md={14} lg={14} xl={14} className = "holdImage">
            <BannerImage />
          </Col>
        </Row>
      </div>

    </Content>
   <img src = {CurveSection} className = "curveSection" style ={{width: "100%"}} />
    <div className = "howItWorks">
      <Content style={{ padding: '50px 50px' }} className = "contained">
        <div className = "titleContainer">
        <h3 style ={{color: "white"}}>Our Prebuilt Themes</h3><div className = "symbol" style = {{marginLeft: 16}}>Click to deploy!</div>
        </div>
        <Row gutter={[64, 16]} style = {{margin: "40px 20px"}}>
          {themePics}
        </Row>
        <div className = "buildYourOwn">
            <div className = "symbol" style = {{marginBottom: 30}}>Live theme Editing!</div>
            <h4 style = {{color: "white"}}>Want to build your own themes or live edit existing ones?</h4>
            <p style={{marginBottom: 40}}>Our theme editor allows you to fully and quickly build bootstrap themes.</p>
            <Link to={"/editor"}><div className = "myButton greenBoi">Open Theme Editor</div></Link>
        </div>
      </Content>
    </div>
   <img src = {CurveSection2} className = "curveSection2" style ={{width: "100%"}} />

    <div className = "myFooter" style={{ textAlign: 'center' }}>
        <Row>
          <Col xs={12} sm={12} md={14} lg={14} xl={14} className = "emailLink">
              <img src={Mail} />
              <h4>team@teamlab.io</h4>
          </Col>
          <Col xs={12} sm={12} md={10} lg={10} xl={10} className = "workWith">
              <h4>Interested in Helping?</h4>
              <p>Enjoy designing or coding? Just want to learn more about the product? Send us an email. We'd love to talk.</p>
          </Col>
        </Row>
    </div>




  </Layout>

    </div>
  );
}

export default Homepage;

    // <div className = "marketNavBack" >
    // <Content style={{ padding: '0px 50px' }} className = "contained">
    //       <div className = "marketNav">
    //         <div className = "buttonContainer2">
    //           <div className = "miniButton active">All</div>
    //           <div className = "miniButton">Popular</div>
    //           <div className = "miniButton">Recent</div>
    //         </div>
    //       </div>

    // </Content>
    // </div>



        // <Menu.Item key="1">Log In</Menu.Item>
        // <Menu.Item key="2">Create Account</Menu.Item>

    // <div className = "marketNavBack" >
    // <Content style={{ padding: '0px 50px' }} className = "contained">
    //       <div className = "marketNav">
    //         <div className = "buttonContainer2">
    //           <div className = "miniButton active">All</div>
    //           <div className = "miniButton">Popular</div>
    //           <div className = "miniButton">Recent</div>
    //         </div>
    //       </div>

    // </Content>
    // </div>


    // <div className = "myAlert">
    //   <p>Want to learn more?</p>
    //   <div className = "myLittleButton">Read our How To</div>
    // </div>


    //     <div className = "marketBack">
    // <Content style={{ padding: '0px 50px' }} className = "contained">


    //       <div className = "market">

    //       <Row>
    //       <Col xs={24} sm={12} md={8} lg={8} xl={8} gutter={16}>
    //         <div className = "themeContainer">
    //           <img src={pic1} />
    //           <div className = "themeCaption">
    //             <div className = "leftSide">
    //               <p>Nikhil</p>
    //             </div>
                // <div className = "rightSide">
                    // <div className = "miniButton">Try it out</div>
                    // <div className = "miniButton">Like</div>
                // </div>
            //   </div>
            // </div>
    //       </Col>
    //       </Row>

    //       </div>


    //




    // </Content>
    // </div>
