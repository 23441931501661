import React from 'react';

import Form from 'antd/es/form';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Button from 'antd/es/button';

function ReplacePasswordForm(props) {
  const { getFieldDecorator } = props.form;

  function handleSubmit(event) {
    event.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.handleSubmit(values);
      }
    });
  }

  return (
    <Form
      onSubmit={handleSubmit}
    >
			<Form.Item>
				{getFieldDecorator('verificationCode', {
					rules: [{ required: true, message: 'Please input your code!' }],
				})(
					<Input
						size = "large" 
						prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
						placeholder="Enter your 6-digit code"
					/>,
				)}
			</Form.Item>
			<Form.Item>
				{getFieldDecorator('password', {
					rules: [{ required: true, message: 'Please input your Password!' }],
				})(
					<Input
						size = "large" 
						prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
						type="password"
						placeholder="New Password"
					/>,
				)}
			</Form.Item>
      <Button type="primary" htmlType="submit">
        Confirm
      </Button>
    </Form>
  );
}

const WrappedReplacePasswordForm = Form.create({ name: 'replace_password_form' })(ReplacePasswordForm);
export default WrappedReplacePasswordForm;
