// Maps the list of CSS components to their proper types
// Called in
export default {
  "border-radius": {
    0: "px"
  },
  "box-shadow": {
    0: "",
    1: "px",
	2: "px",
    3: "px",
    4: "px",
    5: ""
	},
	"border": {
		0: "px",
		1: "",
		2: ""
	},
	"padding": {
		0: "px",
		1: "px"
	},
	"font-size": {
		0: "px"
	},
	"height": {
		0: "px"
	}
};
