import React, { Component } from 'react';

// THIS FILE COULD BE A LOT CLEANER!!! THINK OF WAYS TO BETTER IMPORT AND SET TEXT!!!

// Import other componenets
import CompElement from './CompElement';
import typeToCSS from '../keyValue/typeToCSS.js';


// Import the SVGs
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';

// Takes in CSS styling as props and applies it to the given element
class ElementOption extends Component {

  render() {

    let passData = typeToCSS[this.props.type];

    let elements = []

    const type = this.props.type;

    this.props.color.map(color => {

      elements.push(
          <div key = {color} className = "elementSelectOption elementCenter" onClick = {() => this.props.createNewEl(type, color)}>
              <p className = "title">{color} {this.props.type}</p>
            <CompElement
              key={color}
              css={{
                addClass: passData.addClass,
                classColor: passData.classColor,
                color: color,
                css: {},
                cssClass: passData.class,
                divType: passData.divType,
                type: this.props.type
                }}
              color={color}
              compCreates
            />
          </div>
        )
    })

    return (
        <Tab.Pane eventKey={this.props.eventKey}>
          {elements}
        </Tab.Pane>
    );
  }
}

export default ElementOption;


// <Typography key = {999} id="input-slider" variant = "captions" gutterBottom color="secondary">Button</Typography>
      // <div className = "elOption" onClick = {() => this.props.createNewEl(this.props.type, this.props.color)}>
      