const dev = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://mydfuvklzd.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_I2NAJC2pO",
    APP_CLIENT_ID: "73qbkkj7jsm7ol609tt6dm22ld",
    IDENTITY_POOL_ID: "us-east-1:1aa4d869-b17a-4c09-9541-a1e3e9270256"
  }
};

const prod = {
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://v54spoddhb.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_QNwFx1mjs",
    APP_CLIENT_ID: "7rj4ncnmoeqmapko5dnhm7ejg3",
    IDENTITY_POOL_ID: "us-east-1:289b0e1d-7fbb-4a23-a3d7-f4d75ad6a1e4"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default config;
